<div class="container mt-5 p-4 bg-light rounded shadow">
  <h2 class="text-center text-primary mb-4">Create Your Magical Story</h2>
  <div *ngIf="showForm">
    <form [formGroup]="storyForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
      
      <div class="mb-3">
        <label class="form-label text-success">🌟 Story Title</label>
        <input
          type="text"
          formControlName="title"
          class="form-control border border-info"
          placeholder="Enter an exciting story title"
          (focus)="onFieldFocus('title')"
        />
        <div *ngIf="showErrors['title'] && storyForm.get('title')?.hasError('required')" class="text-danger small-error">
          Story Title is required.
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label text-success">📖 Story Description</label>
        <textarea
          formControlName="description"
          class="form-control border border-info"
          placeholder="Describe the adventure..."
          rows="4"
          (focus)="onFieldFocus('description')"
        ></textarea>
        <div *ngIf="showErrors['description'] && storyForm.get('description')?.hasError('required')" class="text-danger small-error">
          Story Description is required.
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label text-success">👶Age Range</label>
        <div class="d-flex">
          <input
            type="number"
            formControlName="minAge"
            class="form-control me-2 border border-info"
            placeholder="Min Age"
            (focus)="onFieldFocus('minAge')"
          />
          <input
            type="number"
            formControlName="maxAge"
            class="form-control border border-info"
            placeholder="Max Age"
            (focus)="onFieldFocus('maxAge')"
          />
        </div>
        <div *ngIf="showErrors['minAge'] && storyForm.get('minAge')?.hasError('required')" class="text-danger small-error">
          Minimum age is required.
        </div>
        <div *ngIf="showErrors['maxAge'] && storyForm.get('maxAge')?.hasError('required')" class="text-danger small-error">
          Maximum age is required.
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label text-success">🌍 Language</label>
        <input
          type="text"
          formControlName="language"
          class="form-control border border-info"
          placeholder="e.g., English, Spanish"
          (focus)="onFieldFocus('language')"
        />
      </div>

      <div class="mb-3">
        <label class="form-label text-success">🏷️ Tags</label>
        <input
          type="text"
          formControlName="tags"
          class="form-control border border-info"
          placeholder="e.g., Fantasy, Drama"
          (focus)="onFieldFocus('tags')"
        />
      </div>

      <div class="mb-3">
        <label class="form-label text-success">🎯Level</label>
        <input
          type="text"
          formControlName="level"
          class="form-control border border-info"
          placeholder="Enter story level"
          (focus)="onFieldFocus('level')"
        />
      </div>

      <div class="mb-4">
        <label class="form-label fw-bold">🎶Upload Audio </label>
        <input
          type="file"
          (change)="onFileChange($event)"
          class="form-control story-input"
          multiple
          accept=".mp3,.wav,.ogg,.weba"
        />
        <div class="mt-2">
          <ul class="list-group">
            <li *ngFor="let file of selectedFiles; let i = index" class="list-group-item d-flex justify-content-between align-items-center">
              {{ file.name }}
              <button type="button" class="btn btn-danger btn-sm" (click)="removeFile(i)">Remove</button>
            </li>
          </ul>
        </div>
      </div>

      <button 
        class="btn btn-primary btn-lg w-100 mt-4" 
        type="submit" 
        [disabled]="storyForm.invalid || isLoading">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2"></span>
        <span *ngIf="!isLoading">Create Story</span>
      </button>
    </form>
  </div>
</div>
