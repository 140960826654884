export enum ApiEndPoints {
    login = 'admin/login',
    uploadStory = 'story/create',
     findAll = 'story/findAll',
     filter = 'story/filter',
     updateStory = 'story/update',
     findStoryById = 'story/find',
     deleteStory = 'story/delete' 
     
     
  }
  