import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';  
import { AppComponent } from './app.component';  
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { StoryUploadComponent } from './modules/home/story-create/story-upload.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginPageComponent } from './modules/auth/login-page/login-page.component';
import { StoryViewComponent } from './modules/home/story-view/story-view.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { StoryUpdateComponent } from './modules/home/story-update/story-update.component';

@NgModule({
  declarations: [AppComponent,StoryUploadComponent,LoginPageComponent, StoryViewComponent, StoryUpdateComponent],  
  imports: [BrowserModule, AppRoutingModule, ReactiveFormsModule, FormsModule,HttpClientModule,BrowserAnimationsModule,ToastrModule.forRoot()],
  providers: [CookieService],
  bootstrap: [AppComponent], 
})
export class AppModule {}
