import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  loginForm: FormGroup;
  isLoading: boolean = false;
  passwordVisible: boolean = false; 

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiHelper: ApiHelper
  ) {
    this.loginForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
        ]]
    });
  }

  // Function to toggle the visibility of the password
  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  onLoginSubmit(): void {
    if (this.loginForm.invalid) return;

    const credentials = {
      phoneNumber: this.loginForm.value.phoneNumber,
      password: this.loginForm.value.password
    };

    this.apiHelper.post(credentials, ApiEndPoints.login, false).subscribe(
      (response) => {
        const { session, profile } = response.data;
        if (profile.role !== 'admin') {
          alert('Only admin users can log in.');
          return;
        }
        localStorage.setItem('session', JSON.stringify(session));
        localStorage.setItem('profile', JSON.stringify(profile));
        this.router.navigate(['/story-view']);
      },
      (error) => {
        console.error('Login failed', error);
        alert('Login failed. Please check your credentials.');
      }
    );
  }
}
