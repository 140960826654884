<div class="container mt-5 story-container">
  <h2 class="text-center text-primary fw-bold">Update Your Magical Story </h2>
  <form [formGroup]="storyForm" (ngSubmit)="updateStory()" enctype="multipart/form-data">

    
    <div class="mb-4">
      <label class="form-label fw-bold">🌟Story Title</label>
      <input
        type="text"
        formControlName="title"
        class="form-control story-input"
        placeholder="Enter your story's magical title"
        (focus)="onFieldFocus('title')"
      />
      <div *ngIf="showErrors['title'] && storyForm.get('title')?.hasError('required')" class="text-danger small-error">
        Story Title is required.
      </div>
    </div>


    <div class="mb-4">
      <label class="form-label fw-bold">📖Story Description</label>
      <textarea
        formControlName="description"
        class="form-control story-input"
        placeholder="Describe your adventure..."
        (focus)="onFieldFocus('description')"
      ></textarea>
      <div *ngIf="showErrors['description'] && storyForm.get('description')?.hasError('required')" class="text-danger small-error">
        Story Description is required.
      </div>
    </div>

    <div class="mb-4">
      <label class="form-label fw-bold">👶Age Range</label>
      <div class="d-flex">
        <input
          type="number"
          formControlName="minAge"
          class="form-control me-2 story-input"
          placeholder="Min Age"
          (focus)="onFieldFocus('minAge')"
        />
        <input
          type="number"
          formControlName="maxAge"
          class="form-control story-input"
          placeholder="Max Age"
          (focus)="onFieldFocus('maxAge')"
        />
      </div>
    </div>


    <div class="mb-4">
      <label class="form-label fw-bold">🌍Language</label>
      <input
        type="text"
        formControlName="language"
        class="form-control story-input"
        placeholder="e.g., English, Spanish"
        (focus)="onFieldFocus('language')"
      />
    </div>


    <div class="mb-4">
      <label class="form-label fw-bold">🏷️Tags</label>
      <input
        type="text"
        formControlName="tags"
        class="form-control story-input"
        placeholder="e.g., Fantasy, Drama"
        (focus)="onFieldFocus('tags')"
      />
    </div>

    <div class="mb-4">
      <label class="form-label fw-bold">🎯Story Level</label>
      <input
        type="text"
        formControlName="level"
        class="form-control story-input"
        placeholder="Enter story level"
        (focus)="onFieldFocus('level')"
      />
    </div>
    <div class="mb-4" *ngIf="audioUrls.length > 0">
      <label class="form-label fw-bold">🎶Audio Preview</label>
      <div *ngFor="let audio of audioUrls; let i = index" class="d-flex mb-2">
        <audio *ngIf="audio.location" controls>
          <source [src]="audio.location" type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
    


    <div class="mb-4">
      <label class="form-label fw-bold">🎶Upload Audio </label>
      <input
        type="file"
        (change)="onFileChange($event)"
        class="form-control story-input"
        multiple
        accept=".mp3,.wav,.ogg,.weba"
      />
      <div class="mt-2">
        <ul class="list-group">
          <li *ngFor="let file of selectedFiles; let i = index" class="list-group-item d-flex justify-content-between align-items-center">
            {{ file.name }}
            <button type="button" class="btn btn-danger btn-sm" (click)="removeFile(i)">Remove</button>
          </li>
        </ul>
      </div>
    </div>

    <button class="btn btn-success w-100 story-button" type="submit" [disabled]="storyForm.invalid || isLoading">
      <i class="bi bi-upload me-2"></i>
      <span *ngIf="!isLoading">Update Story</span>
      <span *ngIf="isLoading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
    </button>
  </form>
</div>
