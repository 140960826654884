<div class="bg-light dark:bg-transparent min-vh-100 d-flex flex-row align-items-center">
  <div class="container-md">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-xl-8">
        <div class="card-group shadow-lg rounded">
  
          <div class="card p-5">
            <div class="card-body">
              <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                <h1 class="text-center text-primary">🔐 Login</h1>
                <p class="text-muted text-center mb-4">Sign In to your account</p>
                
               
                <div class="mb-3 input-group">
                  <span class="input-group-text bg-info text-white"><i class="fa fa-user"></i></span>
                  <input
                    type="text"
                    formControlName="phoneNumber"
                    placeholder=" Phone Number"
                    class="form-control border border-info"
                  />
                </div>
                <div *ngIf="loginForm.get('phoneNumber')?.hasError('pattern')" class="text-danger small-error mb-2">
                  Phone number must be 10 digits.
                </div>
                
              
                <div class="mb-3 input-group">
                  <span class="input-group-text bg-info text-white"><i class="fa fa-lock"></i></span>
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    formControlName="password"
                    placeholder=" Password"
                    class="form-control border border-info"
                  />
                  <span class="input-group-text cursor-pointer" (click)="togglePasswordVisibility()">
                    <i class="fa" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
                  </span>
                </div>
                <div *ngIf="loginForm.get('password')?.hasError('pattern')" class="text-danger small-error mb-2">
                  Password must contain uppercase, number, and special character.
                </div>

                <div class="d-grid gap-2 mt-4">
                  <button 
                    class="btn btn-primary btn-lg" 
                    type="submit" 
                    [disabled]="loginForm.invalid">
                    <span *ngIf="!isLoading"> Login</span>
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          
         
          <div class="card text-white bg-primary py-5 d-flex align-items-center justify-content-center">
            <div class="card-body text-center">
              <h2 class="text-light"> Welcome to Story Admin</h2>
              <p>Manage and Upload Amazing Stories</p>
          </div>          
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
