import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHelper } from '../../../core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-story-view',
  templateUrl: './story-view.component.html',
  styleUrls: ['./story-view.component.scss'],
})
export class StoryViewComponent implements OnInit {
  stories: any[] = [];
  searchTerm: string = '';

  constructor(private router: Router, private apiHelper: ApiHelper,private toastr: ToastrService ) {}

  ngOnInit(): void {
    this.getStories();
  }

  getStories() {
    const filters = this.searchTerm ? { search: this.searchTerm } : {};

    const apiUrl = this.searchTerm ? ApiEndPoints.filter : ApiEndPoints.findAll;

    this.apiHelper.post(filters, apiUrl, true).subscribe(
      (response) => {
        if (response && response.data) {
          this.stories = response.data;
        } else {
          console.error('No story data found in response');
        }
      },
      (error) => {
        console.error('Error fetching stories', error);
      }
    );
  }

  onSearchChange() {
    this.getStories();
  }

  logout() {
    localStorage.removeItem('session');
    localStorage.removeItem('profile');
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }

  goToStoryUpload() {
    this.router.navigate(['/story-upload']);
  }

  goToUpdatePage(storyId: number) {
    this.router.navigate([`/story-update/${storyId}`]);
  }
  deleteStory(storyId: number) {
    if (confirm('Are you sure you want to delete this story?')) {
      const requestBody = { storyId };

      this.apiHelper.post(requestBody, ApiEndPoints.deleteStory, true).subscribe(
        (response) => {
          console.log(response)
          if (response.data) {
            this.stories = this.stories.filter(story => story.id !== storyId);
            this.toastr.success('Story deleted successfully!', 'Success');
           
          } else {
            this.toastr.error('Error deleting story', 'Error');
          }
        },
        (error) => {
          console.error('Error deleting story', error);
        }
      );
    }
  }
}