import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-story-upload',
  templateUrl: './story-upload.component.html',
  styleUrls: ['./story-upload.component.scss']
})
export class StoryUploadComponent {
  storyForm: FormGroup;
  selectedFiles: File[] = [];
  showErrors: { [key: string]: boolean } = {};  
  showForm: boolean = true; 
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiHelper: ApiHelper,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.storyForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      minAge: [null, [Validators.required, Validators.min(0)]],
      maxAge: [null, [Validators.required, Validators.min(0)]],
      language: ['', Validators.required],
      tags: ['', Validators.required],
      level: ['', Validators.required],
      originalWork: [''] 
    }, { validators: this.ageRangeValidator });
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  onFieldFocus(field: string) {
    this.showErrors[field] = true;
  }

  ageRangeValidator(group: FormGroup) {
    const minAge = group.get('minAge')?.value;
    const maxAge = group.get('maxAge')?.value;
    return minAge && maxAge && minAge <= maxAge ? null : { invalidAgeRange: true };
  }

  onFileChange(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      const files = event.target.files;
      if (files.length > 0) {
        this.selectedFiles = Array.from(files); 
      } else {
        alert('Please select valid audio files.');
      }
    }}


removeFile(index: number) {
  this.selectedFiles.splice(index, 1);
  const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement;
  if (inputElement) {
    const dataTransfer = new DataTransfer();
    this.selectedFiles.forEach((file) => {
      dataTransfer.items.add(file);
    });
    inputElement.files = dataTransfer.files; 
  }
}

  onSubmit() {
    if (this.storyForm.invalid ) {
      alert('Please complete all required fields.');
      return;
    }
    this.isLoading = true;

    const profile = JSON.parse(localStorage.getItem('profile') || '{}');
    const profileId = profile.id || null; 
    if (!profileId) {
      this.toastr.warning('Profile ID not found. Please log in again.', 'Warning');
      this.router.navigate(['/login']);
      return;
    }

    const languages: string[] = this.storyForm.get('language')?.value
      ? this.storyForm.get('language')?.value.split(',').map((lang: string) => lang.trim())
      : [];
    const tags: string[] = this.storyForm.get('tags')?.value
      ? this.storyForm.get('tags')?.value.split(',').map((tag: string) => tag.trim())
      : [];

    const formData = new FormData();
    formData.append('title', this.storyForm.get('title')?.value);
    formData.append('description', this.storyForm.get('description')?.value);
    formData.append('minAge', this.storyForm.get('minAge')?.value.toString());
    formData.append('maxAge', this.storyForm.get('maxAge')?.value.toString());
    formData.append('profileId', profileId);

    languages.forEach((lang) => formData.append('language[]', lang));
    tags.forEach((tag) => formData.append('tags[]', tag));
    formData.append('level', this.storyForm.get('level')?.value);
    if (this.storyForm.get('originalWork')?.value) {
      formData.append('originalWork', this.storyForm.get('originalWork')?.value);
    }
    formData.append('mediaType','audio');
    this.selectedFiles.forEach((file) => {
      formData.append('files', file, file.name);
    });
    this.apiHelper.post(formData, ApiEndPoints.uploadStory).subscribe(
      (response) => {
        this.isLoading = false;
        this.toastr.success('Story uploaded successfully.', 'Success');
        this.router.navigate(['/story-view']);
      },
      (error) => {
        this.isLoading = false;
        console.error('Upload failed', error);
        
        this.toastr.error('Story upload failed.', 'Error'); 
      }
    );
  }

  logout() {
    localStorage.removeItem('session');
    localStorage.removeItem('profile');
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }
}
