import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHelper } from '../../../core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-story-update',
  templateUrl: './story-update.component.html',
  styleUrls: ['./story-update.component.scss']
})
export class StoryUpdateComponent implements OnInit {
  storyForm: FormGroup;
  selectedFiles: File[] = []; 
  showErrors: { [key: string]: boolean } = {};
  showForm: boolean = true;
  storyId!: string;
  audioUrls: { location: string }[] = []; 
  isLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiHelper: ApiHelper,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.storyForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      minAge: [null, [Validators.required, Validators.min(0)]],
      maxAge: [null, [Validators.required, Validators.min(0)]],
      language: ['', Validators.required],
      tags: ['', Validators.required],
      level: ['', Validators.required],
      originalWork: [''] 
    }, { validators: this.ageRangeValidator });
  }

  ngOnInit(): void {
    this.storyId = this.activatedRoute.snapshot.paramMap.get('id') as string;
    if (this.storyId) {
      this.getStoryDetails(this.storyId);
    } else {
      this.router.navigate(['/story-view']);
    }
  }

  getStoryDetails(id: string) {
    const data = { storyId: id };
    this.apiHelper.post(data, ApiEndPoints.findStoryById).subscribe(
      (response) => {
        if (response && response.data) {
          this.storyForm.patchValue({
            title: response.data.title || '',
            description: response.data.description || '',
            minAge: response.data.minAge || null,
            maxAge: response.data.maxAge || null,
            language: response.data.language || '',
            tags: response.data.tags || '',
            level: response.data.level || '',
            originalWork: response.data.originalWork || '' 
          });
           this.audioUrls = response.data.audioFileLocation|| ''; 
        } else {
          console.error('Story data not found in response');
        }
      },
      (error) => {
        console.error('Error fetching story details', error);
      }
    );
  }

  ageRangeValidator(group: FormGroup) {
    const minAge = group.get('minAge')?.value;
    const maxAge = group.get('maxAge')?.value;
    return minAge && maxAge && minAge <= maxAge ? null : { invalidAgeRange: true };
  }

  onFileChange(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      const newFiles: File[] = Array.from(files) as File[];
      newFiles.forEach((file) => {
        if (!this.selectedFiles.some(selectedFile => selectedFile.name === file.name && selectedFile.size === file.size)) {
          this.selectedFiles.push(file);
        }});
        
    } else {
      alert('Please select valid audio files.');
    }}
    removeFile(index: number) {
      this.selectedFiles.splice(index, 1);
      const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement;
      if (inputElement) {
        const dataTransfer = new DataTransfer();
        this.selectedFiles.forEach((file) => {
          dataTransfer.items.add(file);
        });
        inputElement.files = dataTransfer.files; 
      
  }}
  async updateStory() {
    if (this.storyForm.invalid) {
      alert('Please complete all required fields.');
      return;
    }
    this.isLoading = true;
    const profile = JSON.parse(localStorage.getItem('profile') || '{}');
    const profileId = profile.id || null;

    if (!profileId) {
      this.toastr.warning('Profile ID not found. Please log in again.', 'Warning');
      this.router.navigate(['/login']);
      return;
    }

    const languageValue = this.storyForm.get('language')?.value;
    const tagsValue = this.storyForm.get('tags')?.value;

    const languages: string[] = typeof languageValue === 'string'
      ? languageValue.split(',').map((lang: string) => lang.trim())
      : Array.isArray(languageValue) ? languageValue : [];

    const tags: string[] = typeof tagsValue === 'string'
      ? tagsValue.split(',').map((tag: string) => tag.trim())
      : Array.isArray(tagsValue) ? tagsValue : [];

    const formData = new FormData();
    formData.append('title', this.storyForm.get('title')?.value);
    formData.append('description', this.storyForm.get('description')?.value);
    formData.append('minAge', this.storyForm.get('minAge')?.value.toString());
    formData.append('maxAge', this.storyForm.get('maxAge')?.value.toString());
    formData.append('storyId', this.storyId);
    formData.append('profileId', profileId);
    languages.forEach((lang) => formData.append('language[]', lang));
    tags.forEach((tag) => formData.append('tags[]', tag));
    formData.append('level', this.storyForm.get('level')?.value);
    if (this.storyForm.get('originalWork')?.value) {
      formData.append('originalWork', this.storyForm.get('originalWork')?.value);
    }
    formData.append('mediaType','audio');
    this.selectedFiles.forEach((file) => {
      formData.append('files', file, file.name);
    });
   
    this.apiHelper.post(formData, ApiEndPoints.updateStory).subscribe(
      (response) => {
        this.isLoading = false;
        this.toastr.success('Story updated successfully', 'Success');
        this.router.navigate(['/story-view']);
      },
      (error) => {
        this.isLoading = false;
        console.error('Error updating story', error);
        this.toastr.error('Failed to update story', 'Error');
      }
    );
  }

  onFieldFocus(field: string) {
    this.showErrors[field] = true;
  }
}
