<div class="container mt-5 bg-light rounded shadow-lg p-4">
  <h2 class="text-center text-primary fw-bold"> Story Land </h2>


  <div class="dashboard-header d-flex justify-content-between align-items-center my-4">
    <button class="btn btn-danger rounded-pill" (click)="logout()">
      🚪 Logout
    </button>
    <button class="btn btn-success rounded-pill" (click)="goToStoryUpload()">
      ✏️ Create New Story
    </button>
  </div>


  <div class="input-group mb-4">
    <input
      type="text"
      class="form-control rounded-pill"
      [(ngModel)]="searchTerm"
      placeholder="🔍 Search for a story..."
      (input)="onSearchChange()"
      aria-label="Search by title"
    />
    <button class="btn btn-outline-primary rounded-pill" type="button" (click)="getStories()">
      Search
    </button>
  </div>

  
  <div class="stories bg-white p-3 rounded shadow-sm">
    <h4 class="mb-4 text-info">Your Story Collection </h4>

    <div *ngIf="stories.length === 0" class="alert alert-warning text-center">
       No stories found! Create one now!
    </div>

    <table class="table table-hover table-bordered table-striped mt-3" *ngIf="stories.length > 0">
      <thead class="table-primary">
        <tr>
          <th>📝 Title</th>
          <th>🌐 Language</th>
          <th>🎭 Genre</th>
          <th class="text-center">⚙️ Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let story of stories" class="align-middle">
          <td>{{ story.title }}</td>
          <td>{{ story.language }}</td>
          <td>{{ story.tags }}</td>
          <td class="text-center">
            <button
              class="btn btn-warning btn-sm me-2 rounded-circle"
              (click)="goToUpdatePage(story.id)"
              title="Edit Story"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
            >
              ✏️
            </button>
            <button
              class="btn btn-danger btn-sm rounded-circle"
              (click)="deleteStory(story.id)"
              title="Delete Story"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
            >
              🗑️
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
