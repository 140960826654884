import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './modules/auth/login-page/login-page.component';
import { StoryViewComponent } from './modules/home/story-view/story-view.component';
import { StoryUploadComponent } from './modules/home/story-create/story-upload.component';
import { StoryUpdateComponent } from './modules/home/story-update/story-update.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },  
  { path: 'story-upload', component: StoryUploadComponent, canActivate: [AuthGuard] }, 
  { path: 'story-view', component: StoryViewComponent, canActivate: [AuthGuard] },
  { path: 'story-update/:id', component: StoryUpdateComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],  
  exports: [RouterModule]  
})
export class AppRoutingModule {}
